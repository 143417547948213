<div class="relative flex flex-col w-full h-full max-h-[90vh]"
[ngClass]="{'hidden': isDialogHidden}">
    <!-- Content -->
    <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">
        <ng-container>
            <div class="flex flex-col w-full">
                <!-- Title -->
                <div class="text-xl leading-6 font-medium">Custom Date Range</div>

                <form class="mt-8 flex flex-col" [formGroup]="form"
                      #ngForm="ngForm">
                    <!-- Date Range field -->
                    <mat-form-field class="cursor-pointer" [floatLabel]="'always'">
                        <mat-label>Enter a date range</mat-label>
                        <mat-date-range-input class="cursor-pointer" [rangePicker]="picker">
                            <input class="cursor-pointer" [formControlName]="'startDay'" matStartDate readonly
                                   placeholder="Start date" (click)="picker.open()">
                            <input class="cursor-pointer" [formControlName]="'endDay'" matEndDate readonly
                                   placeholder="End date" (click)="picker.open()">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>

                    <!-- Time selection inputs -->
                    <div class="grid grid-cols-2 gap-2">
                        <mat-form-field class="w-full cursor-pointer" [floatLabel]="'always'" [subscriptSizing]="'dynamic'" hideRequiredMarker>
                            <mat-label>Start time</mat-label>
                            <input (click)="isDialogHidden = true" class="cursor-pointer" readonly [ngxTimepicker]="startTimePicker" [formControlName]="'startTime'" matInput placeholder="12:00 AM">
                            <mat-icon class="icon-size-5" [svgIcon]="'mat_outline:schedule'" matSuffix></mat-icon>
                            <ngx-material-timepicker (closed)="isDialogHidden = false" [theme]="primaryTheme" #startTimePicker></ngx-material-timepicker>
                        </mat-form-field>

                        <mat-form-field class="w-full cursor-pointer" [floatLabel]="'always'"
                                        [subscriptSizing]="'dynamic'"
                                        hideRequiredMarker>
                            <mat-label>End time</mat-label>
                            <input (click)="isDialogHidden = true" class="cursor-pointer" readonly [ngxTimepicker]="endTimePicker" [formControlName]="'endTime'" matInput placeholder="11:59 PM">
                            <mat-icon class="icon-size-5" [svgIcon]="'mat_outline:schedule'" matSuffix></mat-icon>
                            <ngx-material-timepicker (closed)="isDialogHidden = false" [theme]="primaryTheme" #endTimePicker></ngx-material-timepicker>
                        </mat-form-field>
                    </div>
                </form>
            </div>
        </ng-container>
    </div>

    <!-- Actions -->
    <ng-container>
        <div class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 dark:bg-black dark:bg-opacity-10">
            <!-- Cancel Action -->
            <ng-container>
                <button mat-flat-button (click)="onCancel()">Cancel</button>
            </ng-container>
            <!-- Confirm Action -->
            <ng-container>
                <button mat-flat-button color="primary" (click)="onSubmit()">Submit</button>
            </ng-container>
        </div>
    </ng-container>
</div>
